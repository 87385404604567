$(window).scroll(function() {
  if ($(this).scrollTop() > 1) {
      $('header').addClass("sticky");
  } else {
      $('header').removeClass("sticky");
  }
});

$('img.lazy').Lazy({
    scrollDirection: 'vertical',
    effect: "fadeIn",
    effectTime: 2000,
    // visibleOnly: true,
    onError: function(element) {
      console.log('error loading ' + element.data('src'));
    }
});